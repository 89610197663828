import Joi from "joi";
import errorMessages from "./errorMessages.json";
import promotionStatuses from "@/fixtures/promotion-statuses";

export default (currencies = []) =>
  Joi.object(
    (() => {
      const schema = {
        book_id: Joi.number().required(),
        start_date: Joi.date().required(),
        end_date: Joi.date()
          .min(Joi.ref("start_date"))
          .required(),
        notes: Joi.string()
          .max(1000)
          .allow(null, ""),
        status: Joi.valid(...promotionStatuses),
        sales_channels: Joi.array().min(1),
        territories: Joi.array().min(0)
      };

      currencies.forEach(c => {
        schema[`price-${c.id}`] = Joi.number()
          .invalid(0)
          .messages({
            "any.invalid": "validation-errors.price-empty"
          });
      });

      return schema;
    })()
  )
    .unknown()
    .messages(errorMessages);
