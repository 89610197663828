<template>
  <div class="add-promotion">
    <ebp-card>
      <template v-if="render">
        <error
          :err="
            $t('promotion-cant-be-edited', {
              status: promotion.status
            })
          "
          v-if="disableEditing"
        />
        <error :err.sync="error" />

        <p>
          You can create price promotions for any of your live titles.
          Promotions can run on Amazon, Apple, Barnes & Noble and Kobo, and must
          have a specified start and end date.
        </p>
        <p>
          You must allow at least 6 days lead time for setting up a new
          promotion and the minimum length of a promotion is 2 days.
        </p>
        <p>
          We cannot control the time of day that promotions will start and end.
          If you are coinciding your promotion with marketing activity, we
          recommend that you set your start date a minimum of one day before and
          your end date a minimum of one day after your marketing activity is
          planned to run.
        </p>

        <p>
          <strong>Note:</strong> Only Published titles can be selected for
          promotions.
          {{
            $t("promotion-min-days-message", {
              days: siteSettings.promotion_min_days
            })
          }}
        </p>
        <form-generator
          :elements="elements"
          :data="promotion"
          :handleUpdate="({ key }, v) => (promotion[key] = v)"
        />
      </template>
      <spinner v-else class="my-5 py-5" />
    </ebp-card>
  </div>
</template>

<script>
import promotionSchema from "@/validation-schemas/promotion";
import FormGenerator from "@/components/form-generator.vue";
import { format, isAfter, addDays, isSameDay, isBefore } from "date-fns";
import { parseISO } from "date-fns/fp";
import { forEach } from "lodash-es";
import promotionStatuses from "@/fixtures/promotion-statuses";

// Helpers

import bookHelper from "@/helpers/book";
import priceHelper from "@/helpers/price";
import promotionHelper from "@/helpers/promotion";

import { mapState } from "vuex";
import Vue from "vue";

export default {
  components: { FormGenerator },
  name: "add-promotion",
  props: {
    usePromotion: Object,
    isAdmin: Boolean
  },
  data() {
    return {
      promotionStatuses: promotionStatuses.map(p => ({
        text: this.$t(p),
        value: p
      })),
      loading: false,
      error: null,
      render: false,
      books: [],
      promotion: {
        book: null,
        book_id: null,
        start_date: format(
          addDays(Date.now(), (this.siteSettings?.promotion_min_days || 7) + 1),
          "yyyy-MM-dd"
        ),
        end_date: null,
        notes: null,
        isbn: null,
        sales_channels: [],
        territories: [],
        base_currency: "GBP",
        user_id: null,
        status: "requested"
      }
    };
  },
  mounted() {
    this.$store.dispatch("ancillaries/getSalesChannels");
    this.$store.dispatch("ancillaries/getTerritories");
    this.$store.dispatch("ancillaries/getValidCurrencies");
    this.$store.dispatch("ancillaries/getCurrencies");
    this.$store.dispatch("siteSettings/get");

    if (this.currencies.length) this.convertFromApiFormat();
  },
  computed: {
    ...mapState("ancillaries", [
      "salesChannels",
      "territories",
      "currencies",
      "validCurrencies"
    ]),
    ...mapState("siteSettings", ["siteSettings"]),
    disableEditing() {
      return (
        this.usePromotion &&
        this.usePromotion.status !== "requested" &&
        !this.isAdmin
      );
    },
    eligibleSalesChannels() {
      return this.salesChannels.filter(channel => channel.does_allow_promos);
    },
    elements() {
      const elements = [
        {
          key: "book",
          label: "book",
          type: "multiselect",
          placeholder: "enter-title-name",
          attrs: {
            loading: this.loading,
            multiple: false,
            options: (() => {
              return this.books;
            })(),
            trackBy: "id",
            customLabel: book => {
              return `${book.title} ${
                book.subtitle ? " - " + book.subtitle : ""
              } ${
                this.isAdmin ? "(ISBN: " + bookHelper.getISBN(book) + ")" : ""
              }`;
            }
          },
          events: {
            "search-change": this.search
          }
        },
        {
          key: "start_date",
          label: "promotion-start-date",
          type: "date",
          small: true,
          attrs: {
            disabledDate: date => {
              const sevenDaysInFuture = addDays(
                Date.now(),
                this.siteSettings?.promotion_min_days || 7
              );
              const endDate = this.promotion.end_date
                ? parseISO(this.promotion.end_date)
                : null;

              if (isBefore(date, sevenDaysInFuture)) {
                return true;
              } else if (endDate && isAfter(date, endDate)) {
                return true;
              } else if (isSameDay(date, endDate)) {
                return true;
              } else if (isSameDay(date, sevenDaysInFuture)) {
                return false;
              } else if (isAfter(date, sevenDaysInFuture)) {
                return false;
              }

              return true;
            }
          }
        },
        {
          key: "end_date",
          label: "promotion-end-date",
          type: "date",
          small: true,
          attrs: {
            disabledDate: date => {
              const sevenDaysInFuture = addDays(
                Date.now(),
                this.siteSettings?.promotion_min_days || 7
              );
              const startDate = this.promotion.start_date
                ? parseISO(this.promotion.start_date)
                : null;

              if (startDate) {
                if (
                  isAfter(date, startDate) &&
                  isAfter(date, sevenDaysInFuture)
                ) {
                  return false;
                }
              } else {
                if (isAfter(date, sevenDaysInFuture)) {
                  return false;
                }
              }

              return true;
            }
          }
        },
        {
          key: "sales_channels",
          label: "sales-channels",
          type: "tree-select",
          attrs: {
            options: this.eligibleSalesChannels,
            trackBy: "name",
            label: "name"
          }
        },
        {
          key: "territories",
          label: "territories",
          type: "tree-select",
          placeholder:
            "Select territories (Leave Blank if you don't want to restrict)",
          className: this.promotion.territories.length ? "mb-3" : "",
          attrs: {
            options: this.territories,
            label: "name",
            trackBy: "id",
            loading: !this.territories.length
          },
          hideIf: "distribute_worldwide"
        },
        {
          key: "notes",
          label: "notes",
          type: "text",
          className: "mb-5"
        },
        {
          key: "base_currency",
          label: "base-currency",
          type: "select",
          attrs: {
            options: this.currencies
              .filter(c => this.validCurrencies.includes(c.id))
              .map(c => ({
                text: c.title,
                value: c.code
              }))
          }
        }
      ]
        .concat(
          this.currencies
            .slice(0)
            .sort((a, b) =>
              b.code === this.promotion.base_currency
                ? 0
                : a.code === this.promotion.base_currency
                ? -1
                : 1
            )
            .filter(c => this.validCurrencies.includes(c.id))
            .map(c => ({
              key: `price-${c.id}`,
              label: this.$t("price-in", { currency: c.code }),
              type: "number",
              placeholder: c.title,
              hideIf: "list_for_free",
              attrs: {
                step: "0.01"
              },
              events: {
                input: val =>
                  this.updatePrices("price-" + c.id, val, this.promotion)
              }
            }))
        )
        .concat([
          {
            type: "button",
            text: this.$t(this.usePromotion ? "save" : "create-promotion"),
            events: {
              click: this.add
            },
            attrs: {
              loading: this.loading
            },
            className: "mt-3"
          }
        ])
        .map(i => {
          i.attrs = {
            ...(i.attrs || {}),
            disabled: this.disableEditing
          };
          return i;
        });

      if (this.isAdmin) {
        elements.unshift({
          key: "status",
          label: "status",
          type: "select",
          attrs: {
            options: this.promotionStatuses
          }
        });
      }

      return elements;
    }
  },
  methods: {
    convertFromApiFormat() {
      // If book not supplied, fallback to create mode
      if (this.$props.usePromotion) {
        const promotion = this.$props.usePromotion;
        forEach(promotion, (value, key) => {
          Vue.set(this.promotion, key, value);
        });

        promotion.prices.forEach(p => {
          Vue.set(this.promotion, "price-" + p.currency_id, p.price);
        });

        this.promotion.sales_channels = promotion.sales_channels.map(c => c.id);
        this.promotion.territories = bookHelper.formatTerritories(
          promotion.territories
        );
      } else {
        this.currencies.forEach(c => {
          Vue.set(this.promotion, "price-" + c.id, 0);
        });
      }

      this.render = true;
    },
    updatePrices(key, val, obj) {
      this.promotion = priceHelper.updatePrices(key, val, obj);
    },
    async search(q) {
      if (q) {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("books/get", {
            search: q,
            status: "live"
          });
          this.books = res.data.data;
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    },
    async add() {
      this.error = null;

      this.promotion.territories = bookHelper.formatTerritoriesForAPI(
        this.promotion.territories
      );
      const { promotion } = this;
      const validation = promotionSchema(this.currencies).validate(
        promotion,
        this.joiOptions
      );
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          await this.$store.dispatch(
            this.usePromotion ? "promotions/update" : "promotions/create",
            promotionHelper.convertToAPIFormat(promotion)
          );
          if (!this.usePromotion)
            this.$router.push({
              name: this.isAdmin ? "AdminViewPromotions" : "ViewPromotions"
            });
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  },
  watch: {
    "promotion.book"(book) {
      this.promotion.book_id = book ? book.id : null;
      this.promotion.isbn = book ? bookHelper.getISBN(book) : null;
    },
    currencies(currencies) {
      if (currencies.length) this.convertFromApiFormat();
    },
    siteSettings(siteSettings) {
      if (siteSettings.promotion_min_days)
        this.$set(
          this.promotion,
          "start_date",
          this.$props.usePromotion
            ? this.$props.usePromotion.start_date
            : format(
                addDays(
                  Date.now(),
                  (this.siteSettings?.promotion_min_days || 7) + 1
                ),
                "yyyy-MM-dd"
              )
        );
    }
  }
};
</script>

<style></style>
