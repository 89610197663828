import { cloneDeep, forEach } from "lodash-es";

export default {
  convertToAPIFormat(promotion) {
    promotion = cloneDeep(promotion);

    promotion.prices = [];
    forEach(promotion, (price, field) => {
      if (field.includes("price-")) {
        promotion.prices.push({
          currency_id: field.split("-")[1],
          price: price || 0
        });
      }
      return field;
    });

    promotion.territories = promotion.territories.map(t => t);
    promotion.user_id = promotion.book.user_id;

    return promotion;
  },
  convertFromAPIFormat(promotion) {
    promotion = cloneDeep(promotion);

    return promotion;
  }
};
